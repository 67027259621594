import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store=new Vuex.Store({
  state:{
    drawer:null,
     application_form_data:{
      admission_form_no:'',
      admission_form_id:''
    },
    form_progress:0,
    quick_links:false,
    form_fees_trans_id:null,
    //fees
    data_for_fees:{
      grno_or_app_form_no:null,
      fees_type:null,
      gr_form:null, //GRNO or FORM
      ay:null
    },
    divoff_id:null,
    ldiv_id:null,
    edit_date:null,
    lid:null,

    //receipt
    receipt_id:null,
    role_type:null,//for dashboard
    company_id:null,
  },
  mutations: {
    getformdata(state, application_form_data) {
      state.application_form_data = application_form_data
    },
    setform_progress(state, form_progress) {
      state.form_progress = form_progress
    },
    setquick_links(state,quick_links){
      state.quick_links = quick_links
    },
    setform_fees_trans_id(state,form_fees_trans_id){
      state.form_fees_trans_id = form_fees_trans_id
    },
    setdata_for_fees(state,data_for_fees){
      state.data_for_fees = data_for_fees
    },
    setdivoff_id(state,divoff_id){
      state.divoff_id = divoff_id
    },
    setldiv_id(state,ldiv_id){
      state.ldiv_id = ldiv_id
    },
    setedit_date(state,edit_date){
      state.edit_date = edit_date},
    setreceipt_id(state,receipt_id){
      state.receipt_id = receipt_id
    },
    setrole_type(state,role_type){
      state.role_type = role_type
    },
    setlid(state,lid){
      state.lid = lid
    },
    setcompany_id(state,id){
      state.company_id = id
    },
  },
  getters: {
    application_form_data: state => state.application_form_data,
    form_progress: state => state.form_progress,
    quick_links: state => state.quick_links,
    form_fees_trans_id: state => state.form_fees_trans_id,
    data_for_fees: state => state.data_for_fees,
    divoff_id: state => state.divoff_id,
    ldiv_id: state => state.ldiv_id,
    edit_date: state => state.edit_date,
    receipt_id: state => state.receipt_id,
    role_type: state => state.role_type,
    lid: state => state.lid,
    company_id: state => state.company_id
  }
});