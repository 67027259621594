const VUAdmission = () => import ('./components/Admission/VUAdmission.vue');
const VUAdmissionApplication = () => import ('./components/Admission/VUAdmissionApplication.vue');
const VUAdmissionApplicationFees = () => import ('./components/Admission/VUAdmissionApplicationFees.vue');
const VUAdmissionApplicationPrint = () => import ('./components/Admission/VUAdmissionApplicationPrint.vue');
const AdmissionPayFeesResponceVU = () => import ('./components/Admission/AdmissionPayFeesResponceVU.vue');
const VUApplicationPrint = () => import ('./components/Admission/VUApplicationPrint.vue');
const ErrorReceiptVU = () => import ('./components/Admission/ErrorReceiptVU.vue');
const ApplicationPayFeesResponceVU = () => import ('./components/Admission/ApplicationPayFeesResponceVU.vue');
const PCUAdmission = () => import ('./components/Admission/PCUAdmission.vue');
const PCUAdmissionReceipt = () => import ('./components/Admission/PCUAdmissionReceipt.vue');
const AdmissionPayFeesResponcePCU = () => import ('./components/Admission/AdmissionPayFeesResponcePCU.vue');

export const routes = [
    {
        path: '/',
        component: VUAdmission
    },
    {
        path: '/vu-admission-application',
        name: 'vu-admission-application',
        component: VUAdmissionApplication
    },
    {
        path: '/vu-admission-application-fees',
        name: 'vu-admission-application-fees',
        component: VUAdmissionApplicationFees
    },
    {
        path: '/vu-admission-application-print',
        name: 'vu-admission-application-print',
        component: VUAdmissionApplicationPrint
    },
    {
        path: '/vu-application-print',
        name: 'vu-application-print',
        component: VUApplicationPrint
    },
    {
        path: '/admission-pay-fees-responce-vu',
        name: 'AdmissionPayFeesResponceVU',
        component: AdmissionPayFeesResponceVU,
    },
    {
        path: '/application-pay-fees-responce-vu',
        name: 'ApplicationPayFeesResponceVU',
        component: ApplicationPayFeesResponceVU,
    },
    {
        path: '/error-receipt-vu',
        name: 'ErrorReceiptVU',
        component: ErrorReceiptVU,
    },
    {
        path: '/pcu-admission',
        component: PCUAdmission
    },
    {
        path: '/pcu-admission-receipt',
        name: 'pcu-admission-receipt',
        component: PCUAdmissionReceipt
    },
    {
        path: '/admission-pay-fees-responce-pcu',
        name: 'AdmissionPayFeesResponcePCU',
        component: AdmissionPayFeesResponcePCU,
    },
    
];