import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#3f51b5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#b71c1c',
            success: '#4caf50',
            info: "#5cbbf6",
            default: "#e0e0e0",
            green:"#4CAF50",
            warning: "#ff9800",
            pink: "#e91e63",
            teal: "#00bcd4",
            lightgreen: "#92c755"
          },
        },
      },
});
