<template>
  <div v-if="isverifymobile && !mobileverified">
    <v-app>
      <v-row height="100%">
        <v-col class="ourprojects" cols="12" sm="6" md="6" style="background:#e2e2e5;">
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
            
              <center>
                <v-img
                  style="width: 230px; height: 188px"
                  alt="School Logo"
                  class="shrink mr-2"
                  contain
                  :src="logo_main"
                  transition="scale-transition"
                />
              </center>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="9" md="9">

                <center>
                    <v-img
                      style="width: 230px; height: 188px"
                      alt="School Logo"
                      class="shrink mr-2"
                      contain
                      :src="logo_main"
                      transition="scale-transition"
                    />
                  </center>
                  <div id="Forgotpassword">
                    <v-form>
                      <div class="text-center">
                        <h3 class="mb-5 mt-4">
                          <strong style="color:#292DC2;">VERIFY MOBILE NUMBER</strong>
                        </h3>
                      </div>
                      <v-text-field
                        v-model="forgot.mobileno"
                        prepend-icon="mdi-message-text"
                        placeholder="Enter Your Mobile Number"
                        persistent-hint
                                :rules="[v => v.length >= 6 && v.length <= 12 || 'Number Should between 6 to 12 digit']"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        clearable
                        required
                        style="border-color:#FFF"
                      ></v-text-field>
                      <v-btn style="color:orange;"
                              class="#292DC2"
                              rounded
                              block
                              @click="sendOTP"
                              color="#292DC2"
                              :loading="loging_sendOTP"
                            >SEND OTP</v-btn>

                            <v-col cols="12" sm="12" md="12">
                              <a
                                class="float-right"
                                style="color: #292dc2; text-decoration: none"
                                @click="cancel()"
                                >SKIP
                              </a>
                             
                            </v-col>      

                    </v-form>
                  </div>

                  <v-form>
                    <div id="veriftotp" style="display:none">
                      <div class="text-center">
                        <h3 class="mb-5 mt-4">
                          <strong style="color:#292DC2;">VERIFY</strong>
                          <strong style="color:orange;">OTP</strong>
                        </h3>
                      </div>
                      <v-text-field
                        v-model="verifyotp1.otp"
                        prepend-icon="mdi-account-key"
                        :rules="[v => !!v || 'Required']"
                        placeholder="Enter OTP"
                        clearable
                        required
                        style="border-color:#FFF"
                      ></v-text-field>
                      <center>
                        <v-btn style="color:orange;"
                          class="#292DC2"
                          rounded
                          block
                          @click="verifyotp"
                          color="#292DC2"
                          :loading="loading_verifyotp"
                        >VERIFY OTP</v-btn>
                      </center>

                       <v-col cols="12" sm="12" md="12">
                              <a
                                class="float-left"
                                style="color: #292dc2; text-decoration: none"
                                @click="cancel()"
                                >SKIP
                              </a>
                             
                            </v-col>     

                    </div>
                  </v-form> 
              </v-col>
                <v-col cols="12" sm="12" md="12">
                <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                  <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
                  <v-btn dark text @click="snackbar = false">Close</v-btn>
                </v-snackbar>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-app>  
  </div> 
  <div v-else>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-app>
      <transition>
        <router-view />
      </transition>
    </v-app>
    <div
      id="quick_link_slider"
      v-if="quick_links"
      class="quick-link-slider-box "
    >
      <div class="quick-link-icon no-display">
        <v-icon
          large
          color="orange darken-2"
          class="icon-quick "
          @click="sliderCloseOpen(false)"
          v-if="isClosed"
          title="Quick Links"
          >mdi-arrow-left-bold-box
        </v-icon>
        <v-icon
          large
          color="orange darken-2"
          class="icon-quick "
          @click="sliderCloseOpen(true)"
          v-if="!isClosed"
          title="Quick Links"
          >mdi-arrow-right-bold-box
        </v-icon>
      </div>

      <div 
        style="position: relative; top: -35px; overflow-y: scroll;height:100%"
      >
        <v-list dense>
          <v-subheader style="font-size: 18px"> Quick Links </v-subheader>
          <v-btn
            fab
            dark
            x-small
            class="quick-link-add-btn"
            @click.native="openDialog()"
            style="background-color:#4caf50;"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-list-item-group color="primary">
            <router-link
              to="/home"
              class="sidebar-link"
              @click.native="sliderCloseOpen(true)"
            >
              <v-list-item link>
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title style="font-size: 1rem"
                    >Home</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-divider></v-divider>

            <div v-for="(qlink, index) in quicklinkslist" :key="index">
              <router-link
                :to="{ name: qlink.link }"
                class="sidebar-link"
                @click.native="sliderCloseOpen(true)"
              >
                <v-list-item link>
                  <v-list-item-icon>
                    <v-icon>{{ qlink.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title style="font-size: 1rem">{{
                      qlink.link_name
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action class="delete-quick-link-div">
                    <v-icon
                      icon
                      class="delete-quick-link-btn"
                      @click.native="deleteQuickLink(qlink)"
                      style="color: grey"
                      title="delete links"
                      >delete</v-icon
                    >
                  </v-list-item-action>
                </v-list-item>
              </router-link>

              <v-divider></v-divider>
            </div>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <!-- End of Quick Link Div --->

    <!-- Quick Link Add Model -->
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add Quick Link
        </v-card-title>

        <v-card-text v-if="all_role_links != null">
          <v-row>
            <v-col cols="12">
              <v-combobox
                v-model="add_link"
                :items="all_role_links"
                item-text="link_name"
                item-value="id"
                label="Add Quick links"
                return-object
                multiple
                chips
                clearable
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="primary" text @click="cancelDialog()" outlined>
            Cancel
          </v-btn>
          <v-btn color="primary" @click="saveQuickLinks">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Quick Link Add Model -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  components: {
  },

  data: () => ({
    msg:"",
    status:"",
    isClosed: true,
    dialog: false,
    quick_links: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    quicklinkslist: null,
    all_role_links: null,
    loging_sendOTP:false,
    loading_verifyotp:false,
    isverifymobile :false,
    mobileverified :false,
    loginid:"",
    logo_main: "https://vierp-test.s3.ap-south-1.amazonaws.com/logo/educamp01.png",
    mobileno:"",
        loging_sendOTP:false,
        forgot: {
        mobileno: "",
        otp: "",
        login:"",
      },
       verifyotp1: {
        mobileno: "",
         otp:"",
         login:"",
      },
      dashboardLearnerProfile:{
        skip:false,
        loginid:"",
        password:"",
      },
      mobileRules: [ 
        v => v => v.length >= 6 && v.length <= 12 || 'Mobile Number must be valid'
      ],
    //2
    add_link: []    
  }),
  methods: {
    openDialog() {
      //location.reload();
      this.dialog = true;
    },
    cancelDialog() {
      this.dialog = false;
      this.sliderCloseOpen(true);
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    sliderCloseOpen(val) {
      var quickslidediv = document.getElementById("quick_link_slider");
      if (val) {
        quickslidediv.style.right = "-256px";
        this.isClosed = true;
      } else {
        this.isClosed = false;
        quickslidediv.style.right = "0px";
      }
    },

    deleteQuickLink(qlink) {
      const formData = {
        qlinkid: qlink.id
      };
      axios
        .post("roleManagement/deleteQuickLinks", formData)
        .then(res => {
          if (res.data.msg == "200") {
            this.quicklinkslist = res.data.quick_links;
            this.all_role_links = res.data.all_role_links;
            this.showSnackbar("#b71c1c", "Deleted successfully..."); // show snackbar on success
          }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {});
      //alert("Delete " + qlink.id);
    },
    saveQuickLinks() {
      if (this.add_link.length == 0) {
        //alert("Please select")
        this.showSnackbar("#b71c1c", "Please Select Quick Link"); // show snackbar on success
        return;
      }
      axios
        .post("roleManagement/saveQuickLinks", this.add_link)
        .then(res => {
          if (res.data.msg == "200") {
            this.quicklinkslist = res.data.quick_links;
            this.all_role_links = res.data.all_role_links;
            this.sliderCloseOpen(false);
            this.dialog = false;
            this.showSnackbar("#4caf50", "Quick Link Added Successfully..!"); // show snackbar on success
          }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {});
    },

    sendOTP() {
       this.loging_sendOTP=true
      axios
        .post("/appLogin/verifymobileno", this.forgot)
        .then(res => {
          this.loging_sendOTP=false
              this.msg = res.data.msg;
            this.status = res.data.status;
          if (this.status == "200") {
        
            this.showSnackbar("green", this.msg);
            document.getElementById("Forgotpassword").style.display = "none";
            document.getElementById("veriftotp").style.display = "block";
          } else {
            
            this.showSnackbar("red", this.msg);
          }
        })
       
    },
     verifyotp(){
      this.loading_verifyotp=true
      this.verifyotp1.mobileno=this.forgot.mobileno
      this.verifyotp1.login = this.forgot.login
         axios
        .post("/appLogin/verifymobileotp", this.verifyotp1)
        .then(res => {
          this.loading_verifyotp=false;
          if (res.data.msg == 200||res.data.msg == '200') {
            this.showSnackbar("#228B22", "OTP Match..");
         
            document.getElementById("veriftotp").style.display = "none";
            this.$router.push("/");
          } else {
            this.showSnackbar("red", res.data.msg);
          }
        })
       
    },
     cancel() {
      this.skip = true
      this.$router.push("dashboardLearnerProfile");
      // this.$router.push({
      //     name: "dashboardLearnerProfile",
      //     params: {skip: this.skip},
      //   });
    },
    //
  },
  watch: {
    "$store.state.quick_links": function() {
      this.quick_links = this.$store.state.quick_links;
      axios
        .post("AppLogin/getUserRoleLinks")
        .then(res => {
          if (res.data.msg == "200") {
            this.quicklinkslist = res.data.quick_links;
            this.all_role_links = res.data.all_role_links;
            this.isverifymobile = res.data.isverifymobile;
            this.forgot.login = res.data.login;
            this.mobileverified = res.data.mobileverified
            this.dashboardLearnerProfile.loginid = res.data.loginid
            console.log(res.data.mobileverified)
          }
        })
        .catch(error => {
          window.console.log(error);
        })
        .finally(() => {});
    }
  }
};
</script>
<style scoped>
.quick-link-slider-box {
  border: 1px solid #0000001f;
  position: fixed;
  top: 9%;
  right: -256px;
  width: 256px;
  height: 91%;

  background-color: white;
  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.theme--light.v-icon {
  color: orange;
}
.quick-link-icon {
  text-align: center;
  width: 28px;
  height: 28px;
  position: relative;
  left: -28px;

  transition-duration: 0.1s;
  transition-delay: 0.2s;
}
.sidebar-link {
  text-decoration: none;
}
.sidebar-link-item:hover {
  background-color: rgb(181, 186, 190) !important;
}
.quick-link-add-btn {
  position: absolute;
  right: 4px;
  top: 8px;
}
@media print {
  body {
    height: 100%;
    width: 100%;
  }
  .no-display{
    display: none !important;
  }
}
.tc-title {
  background-image: linear-gradient(-90deg, #fcc96f, #fcb63c);
  color: #fff;
  font-weight: 700;
  margin: 0px !important;
  padding: 0px !important;
}
.static-data {
  color: indigo;
}
.pull-right {
  position: relative;
  text-align: center;
}
.error-message {
  color: red;
  font-size: 14px;
  font-weight: 900;
  padding-left: 7%;
}
@media screen and (max-width: 600px) {
  .ourprojects {
    display: none !important;
  }
}
</style>
