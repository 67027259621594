import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'
import { routes } from './routes'
import { store } from './store/store'
import axios from 'axios'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vuelidate from 'vuelidate'

import JsonExcel from 'vue-json-excel'

import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueExcelXlsx);

import "@/plugins/echarts";

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)
Vue.component('downloadExcel', JsonExcel)
Vue.use(Vuelidate)
Vue.use(VueRouter);

import CKEditor from 'ckeditor4-vue';
Vue.use(CKEditor);

import VueMathjax from 'vue-mathjax';
Vue.use(VueMathjax);

const router = new VueRouter({
    routes: routes, //routes:routes
    mode: 'history', //  history to remove #
    //  linkActiveClass: 'tag is-active',
})

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API_URL 
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

export default router;